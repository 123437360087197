<template>
  <v-container
    :class="{
      width50: $vuetify.breakpoint.lg,
      width100: $vuetify.breakpoint.md,
    }"
  >
    <div class="tab_container justify-center">
      <div class="tabsHolder">
        <v-tabs
          grow
          height="30"
          hide-slider
          background-color="yellow lighten-4"
          v-model="tab"
          class="ios_tabs"
        >
          <v-tab active-class="tabActive" v-for="(tab, idx) in tabs" :key="idx">
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </div>
      <div class="metric-tabs">
        <div class="tabItemHolder">
          <v-tabs-items v-model="tab" class="tabItem">
            <v-tab-item v-for="(item, idx) in tabs" :key="idx">
              <v-container>
                <div v-for="(tile, idx) in item.tiles" :key="idx" xs12 sm3>
                  <v-row v-if="idx % 2 == 0" no-gutters>
                    <v-col cols="12" sm="6">
                      <v-card flat class="mx-3 my-3">
                        <v-img :src="tile.img" contain height="200px"></v-img
                      ></v-card>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      style="border-right: 2px solid pink"
                    >
                      <v-card-text class="text-h6 justify-center">
                        {{ tile.title }}
                      </v-card-text>
                      <v-card-text class="justify-start">
                        {{ tile.more }}
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-row v-if="idx % 2 != 0" no-gutters>
                    <v-col cols="12" sm="6" style="border-left: 2px solid pink">
                      <v-card-text class="text-h6 justify-center">
                        {{ tile.title }}
                      </v-card-text>
                      <v-card-text class="justify-start">
                        {{ tile.more }}
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-card flat class="mx-3 my-3">
                        <v-img :src="tile.img" contain height="200px"></v-img
                      ></v-card>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </div>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "HowItworks",
  data() {
    return {
      tab: 0,
      tabs: [
        {
          name: "Student",
          tiles: [
            {
              img: require("../../assets/img/git.jpeg"),
              title: "Register and Search for Tutors",
              show: false,
              more: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Totam voluptate modi ducimus aliquam dolores magnam nemopossimus reprehenderit impedit omnis necessitatibus,explicabo debitis cupiditate iure adipisci natus repellendusin. Repellendus?",
            },
            {
              img: require("../../assets/img/git.jpeg"),
              title: "Signup one-on-one or group classes",
              show: false,
              more: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Totam voluptate modi ducimus aliquam dolores magnam nemopossimus reprehenderit impedit omnis necessitatibus,explicabo debitis cupiditate iure adipisci natus repellendusin. Repellendus?",
            },
            {
              img: require("../../assets/img/git.jpeg"),
              title: "Attend classes, rate tutors",
              show: false,
              more: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Totam voluptate modi ducimus aliquam dolores magnam nemopossimus reprehenderit impedit omnis necessitatibus,explicabo debitis cupiditate iure adipisci natus repellendusin. Repellendus?",
            },
            {
              img: require("../../assets/img/git.jpeg"),
              title: "Become something else",
              show: false,
              more: "",
            },
          ],
        },
        {
          name: "Tutor",
          tiles: [
            {
              img: require("../../assets/img/git.jpeg"),
              title: "Register and Search for Tutors",
              show: false,
              more: "",
            },
            {
              img: require("../../assets/img/git.jpeg"),
              title: "Signup one-on-one or group classes",
              show: false,
              more: "",
            },
            {
              img: require("../../assets/img/git.jpeg"),
              title: "Attend classes, rate tutors",
              show: false,
              more: "",
            },
            {
              img: require("../../assets/img/git.jpeg"),
              title: "Become something else",
              show: false,
              more: "",
            },
          ],
        },
      ],
    };
  },
  computed: {
    screenBasedWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "100%" : "50%";
    },
  },
};
</script>
<style scoped>
.tab_container {
  position: relative;
}
.width50 {
  width: 50%;
}
.width100 {
  width: 100%;
}
.ios_tabs {
  border-radius: 50px;
}
.tabsHolder {
  padding: 0.5em 1em;
}
.tabItemHolder {
  border-radius: 5px;
}

.tabActive {
  border-radius: 25px;
  background-color: #fdb139;
  color: white;
}
.v-tabs-items {
  background-color: transparent !important;
}
</style>