let appConfig = null;
const getRuntimeConfig = async () => {
    const runtimeConfig = await fetch('/runtimeConfig.json');
    try {
        return await runtimeConfig.json()
    } catch (ex) {
        window.console.log("Error reading config")
    }

}
export default class AppConfig {
    async load() {
        if (!appConfig) {
            appConfig = await getRuntimeConfig()
            // getRuntimeConfig().then(function (json) {
            //     window.console.log("loaded config")
            //     appConfig = json
            // });
        }
        return appConfig

    }
    getConfig() {
        return appConfig;
    }
}