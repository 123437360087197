<template>
  <div id="app">
    <v-app>
      <app-nav></app-nav>
      <div style="position: fixed; margin: auto">
        <!-- <div class="bgOverlay" /> -->
        <transition name="fade"> </transition>
      </div>
      <v-overlay :value="isWaiting" opacity="0.2">
        <scale-loader
          class="overlay"
          v-show="isWaiting"
          color="#dc5525"
          size="50px"
        ></scale-loader>
      </v-overlay>
      <router-view style="width: 100%; margin-top: 3em"></router-view>
      <v-snackbar
        outlined
        :color="infoBar.color"
        top
        text
        v-model="infoBar.show"
        :timeout="infoBar.timeout"
      >
        {{ infoBar.text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="infoBar.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
import AppNav from "./components/Navigation/Nav.vue";
import { ScaleLoader } from "vue-spinner/dist/vue-spinner.min.js";
export default {
  name: "app",
  components: {
    AppNav,
    ScaleLoader,
  },
  data() {
    return {
      fab: false,
      isWaiting: false,
      infoBar: { show: false, timeout: 3000, text: "", color: "green" },
    };
  },
  computed: {},
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 500;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  created() {
    this.$root.$on("showInfo", (text, type) => {
      this.infoBar.text = text;
      let colorMap = { success: "green", error: "red", info: "primary" };
      this.infoBar.color = colorMap[type] || "primary";
      this.infoBar.show = true;
    });
    this.axios.interceptors.request.use((config) => {
      this.isWaiting = true;
      if (
        !config.url.includes("hereapi") &&
        this.$store.getters.isAuthenticated
      ) {
        var user = this.$store.getters.getUser;
        config.headers["x-access-token"] = user.token;
      }
      return config;
    });
    this.axios.interceptors.response.use(
      (response) => {
        this.isWaiting = false;
        return response;
      },
      (error) => {
        this.isWaiting = false;
        const { status } = error.response;
        if (status === 401) {
          this.$store.dispatch("logout");
          this.$router.push({ name: "app" });
        }
        return Promise.reject(error);
      }
    );
  },
};
</script>

<style>
#app {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.10em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}
@import "./assets/css/culep.css";
</style>
