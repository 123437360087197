<template>
  <div>
    <main-viewer
      style="width: 100%; position: fixed; top: 0; z-index: 100"
    ></main-viewer>
    <user-nav></user-nav>
  </div>
</template>
<script>
import MainViewer from "./MainViewer.vue";
import UserNav from "./UserNav.vue";
export default {
  components: { MainViewer, UserNav },
  name: "AppNav",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped></style>
