import Vue from 'vue';
// import Vuex from 'vuex';
import App from './App.vue';
import router from './router';
import vuetify from '@/plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueApexCharts from 'vue-apexcharts';
import store from './store/store';
import vBlur from 'v-blur';
// import IdleVue from 'idle-vue';
import VueNativeSock from 'vue-native-websocket';
import VueCookies from 'vue-cookies';
import dayjs from 'dayjs'
import Highcharts from 'highcharts';
import Maps from 'highcharts/modules/map';
import SolidGauge from 'highcharts/modules/solid-gauge';
import Bullet from 'highcharts/modules/bullet';
import Stock from 'highcharts/modules/stock';
import hcMore from 'highcharts/highcharts-more';
import HighchartsVue from 'highcharts-vue';
import './registerServiceWorker';
import Autocomplete from '@trevoreyre/autocomplete-vue'
import AppConfig from '../app_configuration';

Maps(Highcharts);
hcMore(Highcharts);
SolidGauge(Highcharts);
Bullet(Highcharts);
Stock(Highcharts);
Vue.use(VueCookies);
Vue.use(HighchartsVue);
Vue.config.productionTip = false;
Vue.use(VueApexCharts);
Vue.use(VueAxios, axios);
Vue.use(vBlur);
Vue.use(dayjs)
Vue.use(Autocomplete)

Vue.use(VueNativeSock, `${store.state.wsUrl}`, {
	connectManually: true,
});

Vue.filter('truncate', function (text, stop, clamp) {
	return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})

Vue.component('apexchart', VueApexCharts);

new AppConfig().load().then((cfg) => {
	Vue.prototype.$config = cfg;
	new Vue({
		router,
		store,
		vuetify,
		render: (h) => h(App),
	}).$mount('#app');
});

