const getDefaultState = () => {
	return {
		loggedIn: false,
		loginError: null,
		user: {
			apiKey: '',
			email: '',
			firstName: '',
			lastName: '',
			userId: '',
		},
	};
};

const state = getDefaultState();

const getters = {
	isAuthenticated: (state) => {
		return state.loggedIn;
	},
	getUser: (state) => {
		return state.user;
	},
	getUserRole:(state)=>{
		return state.user.role
	}
};

const mutations = {
	login(state, loginInfo) {
		state.user = loginInfo.user;
		state.loggedIn = true;
		state.loginError = loginInfo.error;
	},
	logout(state) {
		state.user = null;
		state.loggedIn = false;
		state.loginError = null;
	},
	resetState(state) {
		/* eslint-disable no-debugger*/
		/* eslint-disable no-console*/
		Object.assign(state, getDefaultState());
	},
};

const actions = {
	login(context, user) {
		context.commit('login', user);
	},
	logout(context) {
		context.commit('logout');
	},
	resetState(context) {
		context.commit('resetState');
	},
};

export const user = {
	state,
	getters,
	actions,
	mutations,
};
