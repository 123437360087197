<template>
  <div>
    <div class="content">
      <div class="banner header"></div>
      <v-card
        elevation="10"
        color="#f9f3e0"
        class="header-image"
        v-show="$vuetify.breakpoint.lg"
      >
        <v-img :src="headerImage"></v-img>
      </v-card>
      <v-card flat color="transparent lighten-4" class="header-text">
        <div style="color: purple;">
          <h1>Where Cultures Flourish</h1>
        </div>
      </v-card>

      <v-container>
        <section>
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-card style="padding: 2em" flat color="transparent">
                <v-img
                  contain
                  :src="culture2"
                  class="white--text align-end"
                  height="300px"
                >
                </v-img>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="d-flex justify-center">
                <v-container flat>
                  <div class="text-h6 py-3">Mission</div>

                  With more people leaving their home country and culture behind
                  to explore new opportunities, the younger generations from
                  such families are losing connection with their origins and are
                  not retaining a wealth of knowledge, culture and traditions
                  which has been passed along from multiple centuries. Our
                  mission is to provide a place to connect parents with native
                  tutors giving them an opportunity to learn and let the
                  <span style="color: purple"
                    >cultures prosper for generations</span
                  >
                </v-container>
              </div>
            </v-col>
          </v-row>
        </section>
        <section class="" ref="intro">
          <v-row no-gutters>
            <v-col cols="12" sm="12">
              <div class="d-flex justify-center">
                <v-container flat>
                  <div class="text-h6 py-6">What do we offer</div>
                  A platform to discover tutors, browse and schedule one-to-one
                  sessions as well as group sessions that will enrich younger
                  generations in language, arts and literature. Learning is
                  easier when you have an excellent teacher. Our vetted tutors
                  are passionate about the subjects they teach and bring this
                  enthusiasm into their lessons.
                </v-container>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <div class="d-flex">
                <v-container>
                  <v-card outlined class="pa-4">
                    <div class="text-h6">Parents/Students</div>
                    <v-list>
                      <v-list-item
                        >Explore and learn cultural languages, arts and
                        traditions</v-list-item
                      >
                      <v-list-item
                        >Trail lessons and Easy scheduling</v-list-item
                      >
                      <v-list-item
                        >Transparent pricing and secure online
                        payment</v-list-item
                      >
                    </v-list>
                  </v-card>
                </v-container>
              </div>
            </v-col>

            <v-col cols="12" sm="6">
              <div class="d-flex">
                <v-container>
                  <v-card outlined class="pa-4">
                    <div class="text-h6">Tutors</div>
                    <v-list>
                      <v-list-item>Global reach </v-list-item>
                      <v-list-item> Work flexible hours </v-list-item>
                      <v-list-item>Safe and secure online payment</v-list-item>
                    </v-list>
                  </v-card>
                </v-container>
              </div>
            </v-col>
          </v-row>
        </section>

        <section class="" ref="intro">
          <div class="text-h6 py-6">How it works</div>
          <howitworks></howitworks>
        </section>
        <!-- <v-divider></v-divider> -->
        <section class="" ref="">
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-card class="my-2 mx-6" flat>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div class="text-h5">
                    Connect with Us

                    <v-card-text class="py-0">
                      <a
                        style="text-decoration: none"
                        href="mailto:culep@gmail.com"
                        ><v-icon color="purple">mdi-email-outline</v-icon>
                        culep@gmail.com</a
                      >
                    </v-card-text>
                  </div>
                </v-card-actions>
                <v-divider></v-divider>
                <v-img
                  contain
                  :src="getInTouch"
                  class="white--text align-end my-6 py-6"
                >
                </v-img>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  Take a short survey
                  <v-btn icon @click="openSurvey">
                    <v-icon color="">mdi-open-in-new</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSeV2s9YjEdffRr4Cyff7SopqsAuaY9Nsz6l1D_VMDLO3U06yQ/viewform?embedded=true"
                width="100%"
                height="700"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
                >Loading…</iframe
              >
            </v-col>
          </v-row>
        </section></v-container
      >
    </div>

    <section class="footer" dark ref="footer">
      <v-card dark class="px-5">
        <v-row>
          <v-col cols="12" sm="12">
            <div class="d-flex justify-start">
              Copyright © 2022 Culep - All Rights Reserved.
            </div>
          </v-col>
        </v-row>
      </v-card>
    </section>
  </div>
</template>

<script>
import Howitworks from "./pages/Howitworks.vue";
export default {
  components: { Howitworks },
  name: "Homepage",
  data() {
    return {
      homeImage: require("../assets/img/banner.jpeg"),
      culture2: require("../assets/img/culture_2.jpeg"),
      getInTouch: require("../assets/img/get_in_touch.png"),
      headerImage: require("../assets/img/clc4.jpeg"),
    };
  },

  methods: {
    openSurvey() {
      window.open("https://forms.gle/GiWnwZvVsmdtp6xy8", "_blank");
    },
    openPage(name) {
      this.$router.push({ name: name });
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 500;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    isPageCompleted() {
      /* eslint-disable no-console */
    },
    openDashboard() {
      this.$router.push({ name: "home" });
    },
    scrollToSection(name) {
      var element = this.$refs[name];
      var top = element.offsetTop;
      window.scrollTo(0, top - 100);
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.spaced {
  padding: 1em 0 0.25em 0;
}
.header {
  height: 100%;
  margin-top: -3em;
}
.banner {
  background-image: url("../assets/img/banner.jpeg");
  filter: blur(15px);
  -webkit-filter: blur(15px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  position: relative;
}

.header-text {
  /* font-weight: bold; */
  position: absolute;
  top: 5%;
  right: 40%;
  z-index: 2;
  padding: 20px;
  text-align: center;
  background: yellow;
}

.header-image {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 5%;
  left: 65%;
  right: 10%;
  z-index: 2;
  padding: 20px;
}
.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
  bottom: 0px;
  background-color: #000000;
  /* position: absolute; */

  width: 100%;
}
</style>
