<template>
  <v-flex xs1>
    <v-navigation-drawer
      temporary
      v-model="drawer"
      style="
        position: fixed;
        top: 0;
        left: 0;
        overflow-y: scroll;
        z-index: 1000;
      "
    >
      <v-toolbar flat dark color="#024b60">
        <v-spacer></v-spacer>
        <v-btn text plain>
          <v-icon @click="drawer = false">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list class="d-flex align-start flex-column" v-if="user">
        <v-list-item-avatar size="50">
          <v-img :src="icon"></v-img>
        </v-list-item-avatar>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ user.firstName }} {{ user.lastName }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense class="d-flex align-start flex-column">
        <v-list-item
          link
          v-for="(item, idx) in items"
          :key="idx"
          @click="navItemClick(item)"
        >
          <v-btn text :class="idx === 1 && 'mb-auto'" class="pa-2">
            <v-icon left>{{ item.icon }} </v-icon>
            {{ item.title }}</v-btn
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-flex>
</template>

<script>
export default {
  name: "UserNav",
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  data() {
    return {
      icon: require("../../assets/img/logo.png"),
      items: [
        { title: "Dash", icon: "mdi-home", path_name: "app" },
        { title: "Classes", icon: "mdi-wallet-travel", path_name: "trip" },
        { title: "Profile", icon: "mdi-cogs", path_name: "settings" },
        { title: "About", icon: "mdi-information-outline", path: "about" },
        {},
        {
          type: "button",
          title: "Sign Out",
          icon: "mdi-logout",
          path: "/",
          action: function (component) {
            component.$store.dispatch("logout");
          },
        },
      ],
      drawer: false,
    };
  },
  methods: {
    navItemClick: function (navItem) {
      if (navItem.action) {
        navItem.action(this);
      }
      if (navItem.path_name) this.$router.push({ name: navItem.path_name });
      else if (navItem.path) this.$router.push(navItem.path);
    },
  },
  created() {},
  mounted() {
    this.$root.$on("openUserNav", () => {
      this.drawer = !this.drawer;
    });
  },
  watch: {
    drawer: function (newVal, oldVal) {
      if (newVal != oldVal && !newVal) {
        this.$root.$emit("userNavClosed");
      }
    },
  },
};
</script>

<style></style>
