
const getDefaultState = () => {
	return {};
};

// initial state
const state = getDefaultState();

const getters = {

};

const mutations = {
	updateTripSelection(state, data) {
		state.tripCriteria = data;
	},

	resetState(state) {
		/* eslint-disable no-debugger*/
		/* eslint-disable no-console*/
		Object.assign(state, getDefaultState());
	},
};

const actions = {
	criteria_add_destination(context, data) {
		context.commit('updateDestinationCriteria', data)
	},
	resetState(context) {
		context.commit('resetState');
	},
};

export const appData = {
	state,
	getters,
	actions,
	mutations,
};
