import Vuex from 'vuex';
import Vue from 'vue';

import { user } from './user.js';
import { appData } from './appData.js';
import Cookies from 'js-cookie';
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

const vuexLocal = new createPersistedState({
	key: 'viAAri',
	paths: ['app'],
	storage: window.localStorage,
	modules: ['app'],
	// filter: (mutation) => mutation.type == 'updateDashboard',
});

const vuexCookie = createPersistedState({
	key: 'viAAri',
	paths: ['user'],
	storage: {
		getItem: (key) => {
			return Cookies.get(key)
		},
		setItem: (key, value) => Cookies.set(key, value, {
			expires: 3,
			secure: false
		}),
		removeItem: key => Cookies.remove(key)
	},
	modules: ['user'],
})

const plugins = [vuexLocal, vuexCookie];

export default new Vuex.Store({
	modules: {
		user: user,
		app: appData,
	},
	plugins,
});
