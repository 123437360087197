import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store/store';

import Homepage from '@/components/Homepage.vue';
import NotFound from '@/components/pages/NotFound.vue';
import Wrapper from '@/components/pages/Wrapper.vue';


Vue.use(VueRouter);

// Please import your components and define your route
const router = new VueRouter({
	routes: [{
		path: '/',
		name: 'app',
		component: Homepage,
		meta: { home: true, public: true },
	}, {
		path: '/user-home',
		component: Wrapper,
		children: [

		],
	}, {
		path: '/session',
		component: Wrapper,
		name: 'Session',
		children: [
		],
	}, { path: '*', name: '*', component: NotFound, meta: { back: true, public: true } },
	],
	mode: 'hash',
});
router.beforeEach((to, from, next) => {
	// Prevent access to specific routes based on roles
	let allowed = true;
	if (allowed && !to.meta.public && !store.getters.isAuthenticated) {
		allowed = false;
	}
	if (allowed && to.meta.role && to.meta.role != store.getters.getUserRole) {
		allowed = false
	}

	if (allowed) {
		next();
	} else {
		if (store.getters.isAuthenticated) {
			next('/notfound')
		} else {
			next('/signin');
		}
	}
});
export default router;
